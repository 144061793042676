html,
body,
#root {
	width: 100%;
	height: 100%;
	margin: 0;
	padding: 0;
	font-family: Arial, Helvetica, sans-serif;
}

.webviewer {
	height: calc(100vh - 140px);
}

.startScreen {
	height: 100vh;
}

.fields {
	height: calc(100vh - 140px);
	overflow-y: auto;
}

.fieldOptions {
	height: calc(100vh - 140px);
}